import { ApiClient, InitiateProductSubscription, ProductTypeSubject } from "@t16t/sdk"

type ApiResponse<T> = {
    errorMessage: string | undefined;
    data: T | undefined;
}

export class ProductService {

    async fetchSubjectProducts(): Promise<ApiResponse<ProductTypeSubject[]>> {
        const { data, error } = await ApiClient.getClient().GET('/api/v1/products/:student')
        return {
            errorMessage: error? 'Something went wrong' : '',
            data
        }
    }

    async subscribeToProduct(data: InitiateProductSubscription): Promise<ApiResponse<void>> {
        const { error } = await ApiClient.getClient().POST('/api/v1/subscriptions', {
            body: data,
        })

        return {
            errorMessage: error? 'Something went wrong' : '',
            data: undefined
        }
    }
}